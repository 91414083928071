<template>
  <div>
    <b-card title="Güncelle">
      <b-form-group
        label="Başlık"
        label-for="title"
      >
        <b-form-input
          id="title"
          v-model="notice.title"
          placeholder="Başlık"
        />
      </b-form-group>
      <b-form-group
        label="Metin"
        label-for="content"
      >
        <b-form-textarea
          id="content"
          v-model="notice.content"
          placeholder="Metin"
        />
      </b-form-group>
      <b-form-group
        label="Bitiş Tarihi"
        label-for="edate"
      >
        <b-form-datepicker
          id="edate"
          v-model="notice.edate"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
      </b-form-group>
    </b-card>
    <b-card title="Görsel">
      <b-row>
        <b-col
          v-if="imageUrl"
          xs="12"
          md="3"
        >
          <b-img
            :src="imageUrl"
            fluid
          />
        </b-col>
        <b-col>
          <b-form-group label="Görsel Yükle">
            <b-form-file
              v-model="notice.upload_image"
              placeholder="Bir görsel seçin veya buraya bırakın..."
              drop-placeholder="Görseli buraya bırakın..."
              browse-text="Görsel Seçin"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-button
      variant="danger"
      :disabled="notice.submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BFormDatepicker,
  BButton, BFormFile, BRow, BCol, BImg,
} from 'bootstrap-vue'

export default {
  name: 'Add',
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormDatepicker,
    BFormFile,
    BButton,
  },
  data() {
    return {
      imageUrl: null,
      submitStatus: false,
    }
  },
  computed: {
    notice() {
      const data = this.$store.getters['notices/getNotice']
      if (data.image) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.imageUrl = `${this.$store.state.app.baseURL}/media/portal/notices/${data.image}`
      }
      return data
    },
    saveStatus() {
      return this.$store.getters['notices/getNoticeSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.getNotice()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.notice.submitStatus = false
    },
  },
  beforeCreate() {
    this.$store.dispatch('notices/noticeReset')
  },
  created() {
    this.getNotice()
  },
  methods: {
    getNotice() {
      this.$store.dispatch('notices/noticeView', this.$route.params.id)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.notice.submitStatus = true
      this.$store.dispatch('notices/noticeSave', this.notice)
    },
  },
}
</script>
